import React, { useState, useContext, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { stringTruncate } from "../../Utils/stringTruncate";
import FilterMenu from "../../Components/FilterMenu/FilterMenu";
import { Avatar, Button, Grid, Skeleton, Tooltip, Zoom, tooltipClasses  } from "@mui/material";
import eyeIcon from "../../Assets/Images/eye2.png";
import editIcon from "../../Assets/Images/edit-icon-white.png";
import exportIcon from "../../Assets/Images/export-icon-white.png";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import CreatePatientSet from "../../Components/Modal/CreatePatientSet";
import TablePagination from "@mui/material/TablePagination";
import Alert from "@mui/lab/Alert";
import services from "../../Services/patientServices";
import Backdrop from "@mui/material/Backdrop";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import swal from "sweetalert";
import RemoveIcon from "@mui/icons-material/Remove";
import PatientCoordinator from "../../Components/Modal/PatientCoordinator";
import { AnalyticsContext } from "../../Contexts/analyticsContext";
import { CSVLink } from "react-csv";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { toLowerCase, toCapitalize } from "../../Utils/stringOprations";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import PatientInfo from "../../Components/PatientInfo/PatientInfo";
import { usePatientContext } from "../../Contexts/patientContext";
import { addJoinTypeKeyInFilters, deepClone } from "../../Utils/filterPatientList";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  halfScreen: {
    minHeight: "95vh",
    maxHeight: "95vh",
    minWidth: "50%",
  },
  fullscreen: {
    minHeight: "100vh",
    maxHeight: "100vh",
    minWidth: "100%",
  },
  backdrop: {
    zIndex: 200,
    color: "#78cbf2",
  },
  blue: {
    color: theme.palette.getContrastText("#0080B2"),
    backgroundColor: "#0080B2",
    margin: "0 auto",
    fontSize: "14px",
    height: "30px !important",
    width: "30px !important",
  },
}));

//fetch patients data from props
const FilterTable = (props) => {
  const classes = useStyles();
  const exportPatientRef = useRef();
  const { state, dispatch } = useContext(DropdownFilterContext);
  const { patientSetInfo, analysisType } = useContext(PatientSetContext);
  const [patientListColumns, setPatientListColumns] = useState([]);
  const { selectedColumns, nlp_filter } = state;
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [isExportFetching, setIsExportFetching] = useState(false);
  const { event } = useContext(AnalyticsContext);
  const [patientExportData, setPatientExportData] = useState([]);
  const { updatePatientId, isHalfScreen } = usePatientContext();
  const [expandSection, setExpandedSection] = useState("details");
  const currentUser = localStorage.getItem("user");
  const { user } = JSON.parse(currentUser);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    let columns = selectedColumns;
    if (user.org_guid === "10a7319f-b662-4c79-85fd-7fc5c9cc1461") {
      if (analysisType === "cost_analysis") {
        columns = selectedColumns.filter(
          (i) =>
            i.key !== "cust_care_opportunities" &&
            i.key !== "cust_care_addressed" &&
            i.key !== "cust_eligible_measures"
        );
      }
    }
    setPatientListColumns(columns);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisType, selectedColumns]);

  const closeModal = (close) => {
    setModal(close);
  };

  const sortData = (entity, key, direction) => {
    props.setKey(entity, key, direction);
  };

  const handleChangePage = (e, page) => {
    dispatch({ type: "set-page", payload: page + 1 });
  };

  const { name: patient_set_name } = patientSetInfo;

  const exportIcdCodesData = async () => {
    try {
      const { patient_set_id } = patientSetInfo;
      setIsExportFetching(true);
      const { data } = await services.icdCodesGenerateCSV({
        verified_by_user: user.user_id,
        patient_set_id,
        exporter: `${user.first_name} ${user.last_name}`,
        timezone,
      });
      let csvContent = "data:text/csv;charset=utf-8," + data.csv;
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "IcdCodes.csv");
      document.body.appendChild(link); // Required for FF
      link.click(); // This will download the data file named "my_data.csv".
      setIsExportFetching(false);

      // GA - Track click on Export ICS Codes Data
      event("export_icd_codes", {});
    } catch (error) {
      setIsExportFetching(false);
    }
  };

  const exportData = async () => {
    try {

    setIsExportFetching(true);
    const {
      selectedFilters,
      selectedFiltersCheckbox,
      filteredData,
      nlp_filter,
      inactivePatients,
      totalCount,
    } = state;

    const LIMIT = 10000;
    const MAX_CONCURRENT_REQUESTS = Math.ceil(totalCount/LIMIT);
    const patientData = [];

    if (!filteredData.length || !patientListColumns.length) {
      swal(
        "Oops!",
        "Unable to export. No data available in the table.",
        "error"
      );
      setIsExportFetching(false);
      return;
    }
    const filters = selectedFilters.concat(selectedFiltersCheckbox);
    let filters2 = addJoinTypeKeyInFilters(deepClone(state?.appliedFilterCheckboxes?.appliedFilterCheckboxesList), deepClone(filters))
    const { patient_set_id: pid, selected_date } = patientSetInfo;


    // Function to handle a batch of requests
      const requests = [];

      // Send request in batches 
      for (let i = 0; i < MAX_CONCURRENT_REQUESTS; i++) {
        const offset = i * LIMIT;
        const body = {
          filters: filters2,
          sortBy: {
            direction: "ascending",
            key: "opid",
          },
          columns: patientListColumns,
          selected_date,
          pid,
          inactivePatients,
          nlp_filter: Object.assign(
            ...Object.keys(nlp_filter).map((k) => ({
              [k]: nlp_filter[k].value,
            }))
          ),
          limit: LIMIT,
          offset: offset,
        };
        requests.push(services.generateCsv(body));
      }

      // Resolve all batch request
      const requestsData = await Promise.all(requests);

      // Add data to patientData if there's more data
      requestsData.forEach((requestData) => {
        if (requestData && requestData.data && requestData.data.data.length > 0) {
          patientData.push(requestData.data.data);
        }
      });

      setPatientExportData(patientData.flat());
      setIsExportFetching(false);
      await exportPatientRef.current.link.click();
      // GA - Track click on Export Patients List Data
      event("export_patient_list", {});
    } catch (error) {
      setIsExportFetching(false);
      console.error(error);
    }
  };

  /**
   * Handle CUstumizable Patient term for column
   * @param {*} key
   * @returns
   */
  const handleCustomizablePatientTerm = (key) => {
    switch (key) {
      case "Patient ID":
        return `${toCapitalize(patientSetInfo?.patient_name)} ID`;
      case "Patient Notes":
        return `${toCapitalize(patientSetInfo?.patient_name)} Notes`;
      case "Patient Engagement":
        return `${toCapitalize(patientSetInfo?.patient_name)} Engagement`;
      default:
        return key;
    }
  };

  return (
    <>
      <div className="table-responsive">
        <table
          className="table table-light"
          style={{ marginTop: !patientListColumns.length ? "25px" : "" }}
        >
          <thead>
            <tr>
              {!patientListColumns.length
                ? new Array(15).fill(0).map((_, index) => (
                    <th key={index} style={{ borderRadius: 0 }}>
                      <div className="headings-wrapper">
                        <Skeleton height={70} width={90} />
                      </div>
                    </th>
                  ))
                : patientListColumns
                    .filter((item) => item.key !== "dob")
                    .map((item, index) => {
                      return (
                        <th
                          key={index}
                          className="patient-table-th"
                          style={{ borderRadius: 0,
                             minWidth: item.key.startsWith("cust_") || item.key === 'language' || item.key === 'race' ? "160px" : "130px" }}
                        >
                          <div className="headings-wrapper">
                            <FilterMenu
                              entity={handleCustomizablePatientTerm(item.title)}
                              keyName={item.key}
                              sortDataFunc={sortData}
                              enableSorting={item.enableSorting}
                              statsAvailable={item.statsAvailable}
                              custAttrVal={props.custAttrVal}
                            />
                          </div>
                        </th>
                      );
                    })}
            </tr>
          </thead>
          <tbody>
            {state.isFetching || !patientListColumns.length ? (
              <tr>
                <td colSpan="42">
                  {new Array(10).fill(0).map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      height={30}
                      sx={{ margin: "10px" }}
                    />
                  ))}
                </td>
              </tr>
            ) : !state.isFetching && !state.filteredData.length ? (
              <tr className="mt-4 p-0">
                <td colSpan="42">
                  <Alert
                    icon={false}
                    severity="warning"
                    style={{ justifyContent: "center" }}
                  >
                    No data found.
                  </Alert>
                </td>
              </tr>
            ) : (
              state.filteredData.map((patient, index) => {
                return (
                  <tr key={index} className="patient-list">
                    {patientListColumns
                      .filter((item) => item.key !== "dob")
                      // eslint-disable-next-line
                      .map((column, index) => {
                        if (column.key === "opid")
                          // added icon for redirecting to uat instance for demo purpose
                          return !index &&
                            (window.config?.environment === "demo-a" ||
                              window.config?.environment === "demo") ? (
                            <td>
                              <div style={{ display: "flex" }}>
                                <a
                                  href="/dashboard/1"
                                  target="_self"
                                  rel="noopener noreferrer"
                                  style={{
                                    position: "sticky",
                                    padding: "0 15px",
                                  }}
                                >
                                  <img
                                    src={eyeIcon}
                                    width="18px"
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                  />
                                </a>
                                <div
                                  onClick={() => {
                                    setModal(true);
                                    setExpandedSection("details");
                                    updatePatientId(patient.pid);
                                    event("patient_details_modal", {});
                                  }}
                                  className="patient-id"
                                >
                                  {stringTruncate(patient.opid, 5)}
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setModal(true);
                                setExpandedSection("details");
                                updatePatientId(patient.pid);
                                event("patient_details_modal", {});
                              }}
                              key={index}
                            >
                              <span className="patient-id">
                                {stringTruncate(patient.opid, 5)}
                              </span>
                            </td>
                          );
                        else if (column.key === "pn")
                          return (
                            <td key={index}>
                              <img
                                src={eyeIcon}
                                width="18px"
                                alt=""
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setModal(true);
                                  setExpandedSection("notes");
                                  updatePatientId(patient.pid);
                                  event("patient_notes_modal", {});
                                }}
                              />
                            </td>
                          );
                        else if (column.key === "rec")
                          return (
                            <td
                              key={index}
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setModal(true);
                                setExpandedSection("recommendations");
                                updatePatientId(patient.pid);
                                event("patient_notes_modal", {});
                              }}
                            >
                              {patient[column.key]}
                            </td>
                          );
                        else if (column.key === "pcoor")
                          return (
                            <td key={index}>
                              <Link to="#" style={{ cursor: "pointer" }}>
                                <PatientCoordinator
                                  patientId={patient.pid}
                                  fetchData={props.fetchData}
                                />
                              </Link>
                            </td>
                          );
                        else if (patient[column.key] === null || patient[column.key] === undefined || patient[column.key] === '' )
                          return (
                            <td key={index}>
                              <RemoveIcon className="clear-icon-pl" />
                            </td>
                          );
                        else {
                          const value = String(patient[column.key]);
                          return value.length < 25 ? (
                            <td key={index}>{patient[column.key]}</td>
                          ) : (
                            <Tooltip
                              title={`${value}`}
                              placement="bottom"
                              arrow
                              enterDelay={0}
                              TransitionComponent={Zoom}
                              slotProps={{
                                popper: {
                                  sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                      {
                                        marginTop: '5px',
                                      },
                                  }
                                }}}
                            >
                              <td>{`${
                                String(value).slice(0, 25) + "..."
                              } `}</td>
                            </Tooltip>
                          );
                        }
                      })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <Grid
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={2}
        pt={3}
      >
        <Grid item>
          <TablePagination
            component="div"
            count={state.totalCount}
            page={Number(state.page) - 1}
            rowsPerPage={10}
            rowsPerPageOptions={[]}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ from, to, count }) => {
              if (props.fetchingTotalCount) {
                return <Skeleton width="130px" />;
              } else {
              return `${from}–${to} of ${
                count !== -1 ? nlp_filter["patientsWithEMRNotes"]["value"] === 1 ? `many`: count.toLocaleString() : `more than ${to}`
              }`}
            }}
            sx={{
              "& .MuiTablePagination-spacer": { flex: 0 },
              "& .MuiTablePagination-toolbar": { mt: 0 },
            }}
          />
        </Grid>
        <Grid
          item
          pr={2}
          sx={{
            "& .MuiButton-root": { p: 3.5, m: 1.5, mr: 0, borderRadius: 0 },
          }}
        >
          <Button
            variant="contained"
            onClick={() => exportIcdCodesData()}
            disabled={state.isFetching}
            size="large"
            endIcon={
              <Avatar sx={{ width: 22, height: 22 }} src={exportIcon}></Avatar>
            }
          >
            <Tooltip
              title="Save the static list of verified ICD codes as comma-separated vector file which can be imported into Excel or a database"
              placement="bottom"
              arrow
            >
              <span className="table-btn-span">
                <span>Export Verified ICD-10</span>
              </span>
            </Tooltip>
          </Button>
          <Button
            onClick={() => exportData()}
            disabled={state.isFetching}
            variant="contained"
            size="large"
            endIcon={
              <Avatar sx={{ width: 22, height: 22 }} src={exportIcon}></Avatar>
            }
          >
            <Tooltip
              title={`Save the static list of ${toLowerCase(
                patientSetInfo.patient_name
              )} as comma-separated vector file which can be imported into Excel or a database`}
              placement="bottom"
              arrow
            >
              <span className="table-btn-span">
                <span>Export</span>
              </span>
            </Tooltip>
          </Button>
          <CSVLink
            data={patientExportData}
            filename={`${patient_set_name}`}
            hidden={true}
            ref={exportPatientRef}
            target="_blank"
          />
          <Button
            onClick={() => {
              setModal2(true);
              event("click_create_patient_set", {});
            }}
            disabled={state.isFetching}
            variant="contained"
            size="large"
            endIcon={
              <Avatar sx={{ width: 22, height: 22 }} src={editIcon}></Avatar>
            }
          >
            <Tooltip
              title={`Create new ${toCapitalize(
                patientSetInfo.cohort_name
              )} or append to existing ${toCapitalize(
                patientSetInfo.cohort_name
              )}`}
              placement="bottom"
              arrow
            >
              <span className="table-btn-span">
                <span>Create {toCapitalize(patientSetInfo.cohort_name)}</span>
              </span>
            </Tooltip>
          </Button>
        </Grid>
      </Grid>

      {modal && (
        <Dialog
          classes={{
            paper: isHalfScreen ? classes.halfScreen : classes.fullscreen,
          }}
          open={modal}
          TransitionComponent={Transition}
          onClose={() => setModal(false)}
        >
          <PatientInfo close={closeModal} selectedSection={expandSection} />
        </Dialog>
      )}

      <CreatePatientSet toggle={modal2} setToggle={setModal2} />
      <Backdrop className={classes.backdrop} open={isExportFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FilterTable;
