import React, { useEffect, useContext, useState } from "react";
import Speedometer from "../../Components/Speedometer/Speedometer";
import services from "../../Services/patientServices";
import InterventionWhatIfScenario from "./InterventionWhatIfScenario";
import { InterventionColorContext } from "../../Contexts/interventionColor";
import InterventionFilters from "./InterventionFilters";
import DropdownRiskFilter from "../../Components/FiltersDropdown/DropdownRiskFilter";
import Alert from "@mui/lab/Alert";
import CountSlider from '../../Components/Sliders/CountSlider/CountSlider';
import MemberCountToggle from "../../Components/Toggle/MemberCountToggle";
import useDidMountEffect from "../../Utils/customHooks/useDidMountEffect";
import { AnalyticsContext } from "../../Contexts/analyticsContext";
import GetAppIcon from "@mui/icons-material/GetApp";
import { CSVLink } from "react-csv";
import getDataToExport from "../../Utils/getDataToExport"
import swal from 'sweetalert';
import {useNavigate, useLocation} from "react-router-dom";
import InterventionBarChart from "./InterventionBarChart";
import { MetaDetailsContext } from "../../Contexts/metaDetailsContext";
import { PyramidContext } from "../../Contexts/uiContext";
import { toCapitalize, toPluralize } from "../../Utils/stringOprations";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import AnalysisSkeleton from "../../Components/SkeletonLoader/AnalysisSkeleton";
import "./intervention.scss";

const InterventionAnalysis = (props) => {
  const [initialMount, setInitialMount] = useState(true)
  const history = useNavigate();
  let location = useLocation();
  const { setInfo } = useContext(PyramidContext);
  const { setPageTitle } = useContext(MetaDetailsContext);
  const { intervention, toggleColor } = useContext(InterventionColorContext);
  const { patientSetInfo, analysisType } = useContext(PatientSetContext);

  const [interventionRisk, setInterventionRiskData] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [diagMeterValue, setDiagMeter] = useState(0.5);
  const [diagMeterValueDisplay, setDiagMeterDisplay] = useState(0.5);
  const [meteredValue, setMeteredValue] = useState(0.5);
  const [actions, setActions] = useState({
    reset: false,
    save: true,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [count, setCount] = useState(0);
  const [maxCount, setMaxCount] = useState(0);
  const [isPreCostAvailable, setIsPreCostAvailable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [countType, setCountType] = useState('patient_count');
  const [newCount, setNewCount] = useState(false);
  const [brokenAxisDiag, setBrokenAxisDiag] = useState(false);

  const [sortByOptions, setSortByOptions] = useState([
    {
      id: 0,
      title: 'Savings per member',
      value: 'it.cost_delta',
      order: 'ASC'
    },
    {
      id: 1,
      title: `${toCapitalize(patientSetInfo.cohort_name)} Savings`,
      value: `it.cost_delta * ${countType}`,
      order: 'ASC'
    },
    {
      id: 2,
      title: `${countType === 'patient_count' ? 'Current member count' : 'Individual intervention count'}`,
      value: `${countType === 'patient_count' ? 'patient_count' : 'service_count'}`,
      order: 'DESC'
    },
  ]);

  const itemOptions = [
    {
      id: 0,
      title: 'Top 5',
      count: 5,
    },
    {
      id: 1,
      title: 'Top 10',
      count: 10,
    },
    {
      id: 2,
      title: 'All',
      count: 'all',
    },
  ];
  const [sortBy, setSortBy] = useState(sortByOptions[0]);
  const [items, setItems] = useState(itemOptions[0]);
  const [showDataSource1, setDataSource1] = useState(false);
  const [message, setMessage] = useState('');
  const { event } = useContext(AnalyticsContext);
  const [payload, setPayload] = useState({
    name_of_simulation: "",
    simulated_count: ""
  });

  useEffect(() => {
    let newSortOptions = [
      {
        id: 0,
        title: 'Savings per member',
        value: 'it.cost_delta',
        order: 'ASC'
      },
      {
        id: 1,
        title: `${toCapitalize(patientSetInfo.cohort_name)} Savings`,
        value: `it.cost_delta * ${countType}`,
        order: 'ASC'
      }];
    if (analysisType === 'quality_analysis') {
      newSortOptions = [{
          id: 0,
          title: 'Quality gaps per Member',
          value: 'it.gap_closure_impact',
          order: 'ASC'
        },
        {
          id: 1,
          title: `Quality gaps per ${toCapitalize(patientSetInfo.cohort_name)}`,
          value: `it.baseline_gap_closure * ${countType}`,
          order: 'ASC'
        },
      ]      
      setSortByOptions([...newSortOptions, sortByOptions[2]])
    }
    else {
      setSortByOptions([...newSortOptions, sortByOptions[2]]);
    }
    setSortBy(newSortOptions[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisType]);

  /**
   * Fetch Intervation data from the API
   * @param {*} mount For the reload message.
   */
  const fetchData = async (mount) => {

    if (patientSetInfo) {
      const { patient_set_id: pid, selected_date } = patientSetInfo;

      setIsFetching(true);
      const interventionResponse = await services.interventions(
        {
          filter: {
            sortBy: sortBy.value,
            order: sortBy.order,
            items: items.count,
            countType,
            count,
            dataSource : showDataSource1,
            firstMount: mount
          },
          selected_date
        },
        pid
      );
      setInterventionRiskData(interventionResponse.data.interventions);
      setMeteredValue(interventionResponse.data.risk_score);
      setDiagMeter(interventionResponse.data.risk_score.toFixed(3));
      setIsPreCostAvailable(interventionResponse.data.is_pre_cost_available);
      if (mount && !interventionResponse.data.is_pre_cost_available) {
        setSortHandler(sortByOptions[2]);
      }
      const modifiedInterventions = interventionResponse.data.interventions.map(
        (ele) => {
          let data = {
            abbr: ele.abbr,
            label: ele.label,
            tooltip: ele.tooltip,
            patient_count: ele.patient_count,
            service_count: ele.service_count,
            max_patient_count: ele.max_patient_count,
            max_service_count: ele.max_service_count,
            selected_count: ele.patient_count,
            new_count: 0,
            relative: 0,
            cost_pre: ele.cost_pre,
            cost_post: ele.cost_post,
            score_impact: ele.score_impact,
            intrv_instance: ele.intrv_instance,
            intrv_type: ele.intrv_type
          }
          if (location?.state?.data) {
            const countData = JSON.parse(location.state.data);
            let element = countData.find(el => el.intrv_instance === ele.intrv_instance);
          
            data = { ...data, new_count: element?.selected_count }
            return data
          } else {
            return data;
          }
        }
      );
      setInterventions(modifiedInterventions);
      setIsFetching(false);
      if (modifiedInterventions.length && !maxCount) {
        setSliderMax(modifiedInterventions, 'patient_count');
      }
      if (!modifiedInterventions.length) {
        if (mount) setMessage(`Intervention data for this ${patientSetInfo.cohort_name} is not available yet. Please check back later.`);
        else setMessage("No interventions are matching the current selection criteria.");
      }
      setInitialMount(false);
    }
  };

  useEffect(() => {
    setPageTitle("FHS: Interventions");
    fetchData(true);
    if (location?.state?.data) {
      setNewCount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidMountEffect(() => {
    if (!initialMount) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, items, count, showDataSource1]);

  useEffect(() => {
    if (checked) {
      setCountType('service_count');
      setSliderMax(interventions, 'service_count');
    }
    else {
      setCountType('patient_count');
      setSliderMax(interventions, 'patient_count');
    }
    if (sortBy.id === 2) {
      const option = sortByOptions[2];
      option.title = !checked ? 'Current member count' : 'Individual intervention count';
      option.value = !checked ? 'patient_count' : 'service_count';
      setSortHandler(option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);


  useEffect(() => {
    //calculating the final value for gauge
    if (interventions.length) {
       const sum=interventions.map((item)=>
       {
         return{
          label: item.label,
          intrv_instance: item.intrv_instance,
          selected_count: item.selected_count
        }
      })
      const simulated_count = JSON.stringify(sum);
      setPayload({ ...payload, simulated_count });

      
      let reducedMeterValue = interventions
        .map((ele) => ele.new_count * ele.score_impact)
        .reduce((result, item) => {
          return result + item;
        }, meteredValue);

      setDiagMeter(reducedMeterValue);
      let a = reducedMeterValue;
      setDiagMeterDisplay(a.toFixed(3));
    }
    if (meteredValue === diagMeterValue) {
      toggleColor(false);
    } else {
      toggleColor(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interventions, meteredValue, diagMeterValue, toggleColor]);

  const setSliderMax = (interventions, type) => {
    if (interventions.length) {
      setMaxCount(Math.max(...interventions.map(item => item[type])));
    } else  setMaxCount(0) 
  };

  //change the value of view to "detail" in the context
  const sendViewBack = (label) => {
    setInfo({
      currentui: label,
      nextui: "",
      prevui: "intervention",
    });
    history('/simulations');
  };

  const resetData = async () => {
    fetchData();
    setActions({ reset: true });
    setPayload({
      name_of_simulation: "",
      simulated_count: ""

    })
  };

  const setSortHandler = (option) => {
    setSortBy(option);

    //GA4 - Track set sort by intervention
    event('intervention_sort_by', { type: option.title });
  };


  const saveData = async() => {
      if(!payload.name_of_simulation){
      swal("Oops!", "Please enter a simulation name.", "warning");
      return;
    }else{
      try {
        const { patient_set_id } = patientSetInfo;
        await services
        .createSimutation({ ...payload, cohort_id: patient_set_id })
        setPayload({
          name_of_simulation: "",
          simulated_count: "",  
        })
        swal("Success!", "Simulation created successfully.", "success");
      } catch (error) {
         swal("Oops!", "Simulation with the same name already exists.", "error");
       console.log("err", error);
     }
    }
  }

  if (!patientSetInfo) {   
    history("/")
    return <></>
  } 

  return (
    <Sidebar pageTitle="Foresight Intervention Analysis">
      <div className="emulationOuter app-bg-color pb-3" style={{paddingBottom:"0px"}}>

        <div className="riskFilterOuter">
          <div
            className="diagNosOuter diagNosOuter-resp"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="left-side widget-bg-shadow" id="intervention-dashboard1"
               style={{ width: analysisType !== "cost_analysis" ? '100%' : '70%' }}>
              <div className="flexGroup flexDivide riskFltrBx mb-3">
                <div className="dignoHD">Interventions</div>
                <div className="flexGroup">
                  <form className="filterBx">
                    <div className="filtrDropdown ml-0 mb-3">
                      <label for="exampleSelect">Sort By</label>
                      <DropdownRiskFilter
                        elementId="intervention-dashboard1-dropdown1"
                        setData={setSortHandler}
                        options={sortByOptions}
                        type={"sort"}
                        value={sortBy?.id || 0}
                      />
                    </div>
                  </form>
                  <form className="filterBx">
                    <div className="filtrDropdown ml-0 mb-3">
                      <label for="exampleSelect">Items</label>
                      <DropdownRiskFilter
                        elementId="intervention-dashboard1-dropdown2"
                        setData={setItems}
                        options={itemOptions}
                        type={"count"}
                      />
                    </div>
                  </form>
                  <form className="filterBx">
                    <div className="filtrDropdown flexInner ml-0 mb-3">
                      <div className="slider-container">
                        <label for="exampleSelect" style={{marginBottom: "5px"}}>Counts</label>
                        <CountSlider
                          maxCount={maxCount}
                          count={count}
                          setCount={setCount}
                        />
                      </div>
                    </div>
                  </form>
                  <form className="filterBx">
                    <div className="filtrDropdown flexInner ml-0 mb-3">
                        <input
                         type="checkbox"
                         className="form-check-input"
                         checked={showDataSource1}
                         onChange={()=>{
                          setDataSource1(!showDataSource1);
                        }}
                        />
                      <label >Show Data Source Details</label>
                    </div>
                  </form>
                  <form className="filterBx">
                    <div className="filtrDropdown flexInner ml-0 mb-3">
                        <input
                         type="checkbox"
                         className="form-check-input"
                        checked={brokenAxisDiag}
                        onChange={() => {
                          setBrokenAxisDiag(!brokenAxisDiag);   
                        }}
                        />
                      <label >Broken Axis</label>
                    </div>
                  </form>
                </div>
              </div>
              {isFetching ? (
                <AnalysisSkeleton />
              ) : !isFetching && !interventionRisk.length ? (
                <div className="mt-3">
                  <Alert
                    icon={false}
                    severity="warning"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    {message}
                  </Alert>
                </div>
              ) : (
                <div style={{position: "relative"}}>
                  <div className="intervention-table-header">
                    <div
                      className={
                        intervention.active
                          ? `diabet_left_active`
                          : `diabet_left_inactive`
                      }
                    >
                      <div className="clearfix">
                        <div className={`valueLeftBx ${analysisType === "cost_analysis" ? 'interventionPage' : 'interventionPageFullWidth'}`}>
                          <label className="valueLeft">0%</label>
                          <span className="memberCountContainer">
                            <label className="valueMid">
                              <span
                                className={`${
                                  checked ? "grey-txt" : null
                                } mr-1`}
                              >
                                Member Count
                              </span>
                              <MemberCountToggle
                                checked={checked}
                                setChecked={setChecked}
                              />
                              <span
                                className={`${
                                  !checked ? "grey-txt" : null
                                } ml-1`}
                              >
                                Individual Intervention Count
                              </span>
                            </label>
                          </span>
                          <label className="valueRight">100%</label>
                        </div>
                      </div>
                    </div>
                    <div className="diabet_right filter-heading-1">
                      <table width="100%" cellPadding="0" cellSpacing="0">
                        <thead>
                          <tr>
                            {analysisType === "cost_analysis" ?
                              <><th width="20%" className="table-heading">
                                Savings PMPM
                              </th>
                                {isPreCostAvailable ? (
                                  <th width="30%" className="table-heading">
                                    Cost Before Intervention
                                  </th>
                                ) : null}
                                <th width="40%" className="table-heading">
                                  Annual Savings
                                  <br /> (individual)
                                </th></> :
                                <>
                                  <th width="50%" className="table-heading">
                                    Gap Closure Impact
                                  </th>
                                  <th width="50%" className="table-heading">
                                    Baseline Gap Closure
                                  </th>
                                </>}
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  <InterventionFilters
                    interventionData={interventionRisk}
                    isPreCostAvailable={isPreCostAvailable}
                    countType={countType}
                    showDataSource={showDataSource1}
                    showBrokenAxis={brokenAxisDiag}
                    analysisType={analysisType}
                  />
                </div>
              )}
            </div>
            {analysisType === "cost_analysis" && <div className="right-side" style={{ display: "flex" }}>
              <Speedometer
                value={meteredValue}
                heading={"Population Risk Score Today"}
                type="diagnosis"
                label={"Comprehensive Risk Score"}
              />
            </div>}
          </div>
          <div
            className="diagNosOuter diagNosOuter-resp"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="left-side widget-bg-shadow" id="intervention-dashboard2"
              style={{ width: analysisType !== "cost_analysis" ? '100%' : '70%' }}>
              <div className="dignoHD">
                Interventions Provided -What-If Scenario
              </div>
              {isFetching ? (
                <AnalysisSkeleton />
              ) : !isFetching && !interventions.length ? (
                <div className="mt-3">
                  <Alert
                    icon={false}
                    severity="warning"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    {message}
                  </Alert>
                </div>
              ) : (
                <div>
                  <div
                    className="diabetOut"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: 23,
                    }}
                  >
                    <div
                      className={
                        intervention.active
                          ? `diabet_left_active`
                          : `diabet_left_inactive`
                      }
                    >
                      <div className="clearfix">
                        <div className="valueLeftBx ">
                          <label className="valueLeft">0%</label>
                          <span className="memberCountContainer">
                            <label className="valueMid">
                              <span
                                className={`${
                                  checked ? "grey-txt" : null
                                } mr-1`}
                              >
                                Member Count
                              </span>
                              <MemberCountToggle
                                checked={checked}
                                setChecked={setChecked}
                              />
                              <span
                                className={`${
                                  !checked ? "grey-txt" : null
                                } ml-1`}
                              >
                                Individual Intervention Count
                              </span>
                            </label>
                          </span>
                          <label className="valueRight">100%</label>
                        </div>
                      </div>
                    </div>

                    <div className="diabet_right" style={{width: analysisType === "quality_analysis" && '450px'}}>
                      <table width="100%" cellPadding="0" cellSpacing="0">
                        <thead>
                          {analysisType === "cost_analysis" ? <tr>
                            <th width="33.33%">Current Count</th>
                            <th width="33.33%">Additional {toPluralize(patientSetInfo.patient_name)}</th>
                            <th width="33.33%">
                              Annual Savings (addtl, cumulative)
                            </th>
                          </tr> :
                            <tr>
                              <th width="25%">Members Intervened</th>
                              <th width="25%">Members to be Intervened</th>
                              <th width="25%">Present closure percentage</th>
                              <th width="25%">Effect on gap closure</th>
                            </tr>}
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                  <InterventionWhatIfScenario
                    actions={actions}
                    setActions={setActions}
                    interventions={interventions}
                    setinterventions={setInterventions}
                    countType={countType}
                    newCount={newCount}
                    analysisType={analysisType}
                  />

                  <div className="export-container">
                    <span
                      onClick={() => sendViewBack("simulations")}
                      className="export-Btn"
                    >
                      <span>View Simulations</span>
                    </span>
                    <CSVLink
                      data={
                        interventions.length > 0
                          ? getDataToExport(interventions, "Intervention Provided")
                          : []
                      }
                      filename={`Interventions Sort By=${sortBy?.title} Items=${items.title} Counts=${count}.csv`}
                      className="export-Btn"
                    >
                      <span className="table-btn-span">
                        <span>Export</span>
                        <GetAppIcon className="table-icon-1" />
                      </span>
                    </CSVLink>
                  </div>
                </div>
              )}

              {intervention.active && interventions.length ? (
                <div className="active-input-simulation mb-2">
                  <div>
                    Save simulation as
                    <input
                      type="text"
                      value={payload.name_of_simulation}
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          name_of_simulation: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="simulation-btn">
                    <button
                      type="button"
                      className="simulation-save"
                      onClick={() => saveData()}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="simulation-reset"
                      onClick={resetData}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
            {analysisType === "cost_analysis" && <div className="right-side">
              <Speedometer
                type="intervention"
                diagvalue={diagMeterValueDisplay}
                interventions={interventions}
                heading={"Predicted Population Risk Score"}
                label={"Comprehensive Risk Score"}
              />
            </div>}
          </div>
          <InterventionBarChart />

        </div>
      </div>
    </Sidebar>
  );
};

export default InterventionAnalysis;