import React, { useState, useContext, useEffect } from "react";
import withRouter from "../../Utils/withRouterHOC";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import "./_PatientList.scss";
import {
  Accordion,
  AccordionSummary,
  Box,
  Drawer,
  ListSubheader,
  Skeleton,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { toPluralize } from "../../Utils/stringOprations";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IndeterminateInput from "../../Components/Indeterminate/IndeterminateInput";
import useDidMountEffect from "../../Utils/customHooks/useDidMountEffect";
import OutlierFilter from "./OutlerFilter";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";
import AlgoliaAutocomplete from "../../Components/Autocomplete/Autocomplete";

const appId = "LK0QLFUCKW";
const apiKey = "016f51b4cf791ea2696890563334122f";
const searchClient = algoliasearch(appId, apiKey);

const AdvancedFiltersList = ({
  open,
  toggleDrawer,
  fetchData,
  options,
  handleChangeNLPFilter,
  onChange,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [originalFilterList, setOriginalFilterList] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [indexName, setIndexName] = useState(null);

  const { state, dispatch } = useContext(DropdownFilterContext);
  const { patientSetInfo } = useContext(PatientSetContext);

  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleInactiveCheckbox = (event) => {
    dispatch({ type: "set-inactive-patients", payload: event.target.checked });
  };

  useDidMountEffect(() => {
    dispatch({ type: "set-page", payload: 1 });
    fetchData("checkbox filter", { ...state, page: 1 });
  }, [JSON.stringify(state.selectedFiltersCheckbox)]);

  useEffect(() => {
    setOriginalFilterList([...options]);

    // Set org_id and env specific index name
    const { org_id } = JSON.parse(localStorage.getItem("user")).user;
    setOrgId(org_id);

    var environment = window.config.environment === 'local' ? 'dev' : window.config.environment;
    setIndexName(`${environment}_PATIENT_LIST_FILTERS`);

  }, [options, open]);

  return (
    <Drawer
      anchor={"left"}
      open={open}
      onClose={toggleDrawer("left", false)}
      className="advance-filter-outer-container"
      transitionDuration={1}
    >
      <Box role="presentation" className="inner-container">
        <List className="filter-list">
          <ListSubheader className="list-header">
            COMMON FHS FILTERS
          </ListSubheader>
          {originalFilterList.length > 0 ? (
            originalFilterList.map((ele, id) => (
              <div className="filter-accordion-container" key={id}>
                <Accordion
                  key={id}
                  expanded={expanded === `panel${id}`}
                  onChange={handleExpandChange(`panel${id}`)}
                  className="filter-accordion"
                >
                  <AccordionSummary
                    key={ele.key}
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="title">{ele.title}</Typography>
                  </AccordionSummary>
                  <div className="accordion-details-container">
                    {ele.type !== "static" && ele.type !== "outlier" && (
                      <AlgoliaAutocomplete
                        placeholder={`Search ${ele.title}`}
                        openOnFocus={true}
                        getSources={({ query }) => [
                          {
                            sourceId: "codes",
                            getItems() {
                              return getAlgoliaResults({
                                searchClient,
                                queries: [
                                  {
                                    indexName,
                                    query,
                                    params: {
                                      hitsPerPage: 100,
                                      filters: `title:'${ele.title}' AND org_id:${orgId}`,
                                    },
                                  },
                                ],
                              });
                            },
                            templates: {
                              item({ item, components }) {
                                return (
                                  <ProductItem
                                    hit={item}
                                    components={components}
                                    onChange={onChange}
                                    id={id}
                                    options={ele.options}
                                  />
                                );
                              },
                            },
                          },
                        ]}
                        classNames={{
                          panel: "panel-pos-unset"
                        }}
                        panelRefSameAscontainer
                      />
                    )}
                    {ele.type === "static" &&
                      ele.options.map((item, i) => {
                        return item.label && item.name ? (
                          <StaticItem
                            item={item}
                            onChange={onChange}
                            id={id}
                          />
                        ) : null;
                      })}
                    {ele.type === "outlier" &&
                      ele.options.map((item, i) => {
                        return item.label && item.name ? (
                          <OutlierFilter
                            key={ele.key}
                            id={i}
                            name={item.name}
                            filter={item}
                            selected={item.selected}
                            onChange={onChange}
                          />
                        ) : null;
                      })}
                  </div>
                </Accordion>
              </div>
            ))
          ) : (
            <div className="filter-loader-container">
              {[...Array(5)].map((_, index) => (
                <Skeleton key={index} height={50} sx={{ width: "80%" }} />
              ))}
            </div>
          )}
        </List>
        {/* <Divider /> */}
        <List className="filter-list">
          <ListSubheader className="list-header">OTHER FILTERS</ListSubheader>
          <ListItem disablePadding className="list-item-container">
            <ListItemButton
              role={undefined}
              onClick={handleInactiveCheckbox}
              dense
              className="others-filters-button"
            >
              <ListItemText
                primary={`Include Inactive ${toPluralize(
                  patientSetInfo.patient_name
                )}`}
                sx={{ color: "#0080b2" }}
              />
              <ListItemIcon>
                <Checkbox
                  checked={state?.inactivePatients}
                  onChange={handleInactiveCheckbox}
                  tabIndex={-1}
                  style={{
                    color: "#0080b2",
                  }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          {Object.keys(state.nlp_filter).map((item, key) => (
            <ListItem disablePadding className="list-item-container" key={key}>
              <ListItemButton
                role={undefined}
                onClick={() =>
                  handleChangeNLPFilter(state.nlp_filter[item].name)
                }
                dense
                className="others-filters-button"
                disabled={state.nlp_filter[item].disabled}
              >
                <ListItemText
                  primary={state.nlp_filter[item].label}
                  sx={{ color: "#0080b2" }}
                />
                <ListItemIcon>
                  <Checkbox
                    disabled={true}
                    checked={state.nlp_filter[item].value === 1}
                    indeterminate={state.nlp_filter[item].value === 2}
                    onChange={() =>
                      handleChangeNLPFilter(state.nlp_filter[item].name)
                    }
                    name={state.nlp_filter[item].name}
                    style={{
                      color: "#0080b2",
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

const ProductItem = ({ hit, components, onChange, id, options }) => {
  const name = `${hit.mnemo}_${hit.abbr}`;
  const findItem = options.find((item) => item.name === name);
  return (
    <>
      <div
        className="aa-ItemContent"
        style={{ height: "100%" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <div className="aa-ItemTitle">
          <IndeterminateInput
            key={id}
            id={id}
            name={name}
            selected={findItem.selected}
            onChange={onChange}
            mnemo={hit.mnemo}
            component={components}
            hit={hit}
          />
        </div>
      </div>
    </>
  );
};

const StaticItem = ({ item, onChange, id }) => {
  return (
    <>
      <div
        className="aa-ItemContent"
        style={{ height: "100%" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <div className="aa-ItemTitle">
          <IndeterminateInput
            key={id}
            id={id}
            name={item.name}
            selected={item.selected}
            value={item.label}
            onChange={onChange}
            mnemo={item.mnemo}
            component={null}
            hit={item}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(AdvancedFiltersList);
