import React, { useEffect, useContext, useState,useMemo } from "react";
import "./Contributors.scss";
import Speedometer from "../../Components/Speedometer/Speedometer";
import CustomizedSlider from "../../Components/Sliders/CustomizedSlider/CustomizedSlider";
import services from "../../Services/patientServices";
import SegmentedSlider from "../../Components/Sliders/SegmentedSlider/SegmentedSlider";
import {MenuItem, Select, Tooltip} from '@mui/material';
import addIcon from "../../Assets/Images/add-icon.png";
import Alert from '@mui/lab/Alert';
import DropdownRiskFilter from "../../Components/FiltersDropdown/DropdownRiskFilter";
import CountSlider from '../../Components/Sliders/CountSlider/CountSlider';
import useDidMountEffect from "../../Utils/customHooks/useDidMountEffect";
import { stringTruncate } from "../../Utils/stringTruncate";
import { AnalyticsContext } from "../../Contexts/analyticsContext";
import GetAppIcon from "@mui/icons-material/GetApp";
import { CSVLink } from "react-csv";
import getDataToExport from "../../Utils/getDataToExport"
import MixedChart from "../../Components/Charts/MixedChart";
import settingIcon from "../../Assets/Images/setting-icon.png";
import BrokenAxisRangeSlider from "../../Components/Sliders/BrokenAxisRangeSlider/BrokenAxisRangeSlider";
import PieChart from "../../Components/Charts/PieChart";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { useNavigate } from "react-router-dom";
import { MetaDetailsContext } from "../../Contexts/metaDetailsContext";
import { toCapitalize, toLowerCase } from "../../Utils/stringOprations";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { PyramidContext } from "../../Contexts/uiContext";
import AnalysisSkeleton from "../../Components/SkeletonLoader/AnalysisSkeleton";

const createPieChartSeries = (data, columnKey, labelKey) => {
  const series = [];

  const options = {
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(2) + "%"
      },
      style: {
        colors: ["#000"],
        fontWeight: 'normal'
      },
      dropShadow: {
        enabled: false,
      }
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val.toFixed(2) + "%"
        },
      }
    },
    legend: {
      width: 400
    },    
    labels: [],
    colors: ['#88d479', '#a6e7db', '#fcad60', '#f4755f', '#0082b2'],
 };

  let width = 700;

  data.forEach((item) => {
    series.push(item.contrib);
    const label = item[labelKey].replace(/_/g, " ");
    options.labels.push(label);
  });

  for (let i = 1; i < series.length; i++){
    for (var j = 0 ; j < i; j++)
    if (series[i] > series[j]) {
      const temp = series[i];
      series[i] = series[j]
      series[j] = temp;
      const tempLabel = options.labels[i];
      options.labels[i] = options.labels[j];
      options.labels[j]= tempLabel
    }
  }

  const usedPortion = series.reduce((a, b) => a + b, 0);
  if (100 - usedPortion > 0) {
    series.push(100 - parseFloat(usedPortion).toFixed(2))
    options.labels.push("Others")
  }

  return { series, options, width };
};

const createMixedChartSeries = (data, columnName, lineName, columnKey, lineKey, labelKey, title, xaxis, yaxis, showCurrency = true, tooltip = {}) => {
  const series = [{
    name: columnName,
    type: 'column',
    data: [],
  }, {
    name: lineName,
    type: 'line',
    data: []
  }];

  const options = {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: [0, 0]
    },
    markers: {
      size: [6, 6]
    },
    title,
    plotOptions: {
      bar: {
        borderRadius: 10,
        horizontal: false,
        // rangeBarOverlap: false,
        hideOverflowingLabels: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1],
      formatter: function (val, opts) {
        if (val % 1 !== 0) {
          if (showCurrency) {
            return "$" + val.toFixed(2)
          }
          return val.toFixed(2)
        }
        return opts.seriesIndex === 0 ? val : (showCurrency ? "$" : "") + val
      },
      offsetY: -12,
      style: {
        fontSize: "10px",
        colors: ['#333333'],
      },
      background: {
        enabled: false
      }
    },
    labels: [],
    xaxis,
    yaxis,
    tooltip,
    legend: {
      markers: {
        radius: 12,
      }
    }
  }

  data.map(item => {
    series[0].data.push(item[columnKey]);
    series[1].data.push(item[lineKey] > 0 ? item[lineKey] : 0.00);
    const label = item[labelKey].includes(" - ") ? item[labelKey].split(" - ")[1] : item[labelKey];
    // label.length > 10 ? middleTruncate(label, 20) : label
    options.labels.push(label);
    return false;
  })

  return { series, options };
}

/**
 * Create the analysis sliders according the provided data
 * @param {*} param0 
 * @returns Rendered Component 
 */
const AnalysisSliders = ({ contributorData, showDataSource, showBrokenAxis, maxCount, analysisType }) => {

  /**
   * Remove code from contributor lable
   * @param {*} text 
   * @returns 
   */
  const getSplitString = (text) => {
    if (typeof text !== 'string') return '';
    const splitText = text.split(" - ");
    return splitText.length > 1 ? splitText[1] : text;
  };

  /**
   * For class index fillter for data sources display
   * @param {*} data 
   * @returns 
   */
  const filterClassIndexes = (data) => {
    var slideIndexArr = [];
    data.forEach(item => {
      item?.data_sources?.forEach(section => {
        slideIndexArr = [...slideIndexArr, section.note];
      });
    });
    return [...new Set(slideIndexArr)];
  };

  return contributorData.map((ele) => {
    const riskCoeff = analysisType === "quality_analysis" ? parseFloat(ele.coeff_quality).toFixed(3) : parseFloat(ele.coeff).toFixed(3);
    const riskContri = analysisType === "quality_analysis" ? parseFloat(ele.contrib_quality).toFixed(2) : parseFloat(ele.contrib).toFixed(2);
    return (
      <div
        key={ele.id}
        className="diabetOut contributor-row"
      >
        <div className="diabet_left_inactive ca-flex" >
          <Tooltip
            placement="top"
            title={ele.tooltip}
            interactive="true"
            arrow
          >
            <span className="diabLabl">{stringTruncate(getSplitString(ele.label), 45)}</span>
          </Tooltip>

          <div className="diabMetr clearfix" key={ele.id}>
            {
              (showDataSource || contributorData) && showBrokenAxis ?
                <BrokenAxisRangeSlider
                  key={ele.id}
                  minElig={0}
                  maxElig={ele.max_elig}
                  sections={ele.data_sources}
                  sliderValue={ele.current_count}
                  issue={ele.label}
                  tooltip={ele.tooltip}
                  contr_type={ele.id}
                  abbr={ele.abbr}
                  maxCount={maxCount}
                  mnemo={ele.mnemo}
                  slideClassIndexes={filterClassIndexes(contributorData)}
                  type={"contribution"}
                /> : (showDataSource || contributorData) && !showBrokenAxis ?
                  <SegmentedSlider
                    key={ele.id}
                    minElig={0}
                    maxElig={ele.max_elig}
                    sections={ele.data_sources}
                    sliderValue={ele.current_count}
                    issue={ele.label}
                    tooltip={ele.tooltip}
                    contr_type={ele.id}
                    abbr={ele.abbr}
                    mnemo={ele.mnemo}
                    slideClassIndexes={filterClassIndexes(contributorData)}
                    type={"contribution"}
                  /> :
                  <CustomizedSlider
                    key={ele.id}
                    minElig={0}
                    maxElig={ele.max_elig}
                    sliderValue={ele.current_count}
                    mnemo={ele.mnemo}
                  />
            }
          </div>
        </div>

        <div className="diabet_right">
          <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
              <tr key={ele.id} className="contributor-left-row">
                <td width="33.33%">{Number(riskCoeff) || '-'}</td>
                <td width="33.33%">{Number(riskContri) ? (riskContri + '%') : '-'}</td>
                {analysisType === "cost_analysis" && <td width="33.33%">${parseInt(ele.pmpm, 10)}</td>}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  });
}

// Contributor Analysis Page Componant
const ContributorAnalysis = () => {
  const history = useNavigate();
  const { setPageTitle } = useContext(MetaDetailsContext);
  const { event } = useContext(AnalyticsContext);
  const { patientSetInfo, analysisType } = useContext(PatientSetContext);
  const { info } = useContext(PyramidContext);

  const [diagnosisData, setDiagnosisData] = useState([]);
  const [sdohData, setSdohData] = useState([]);
  const [speedometerValues, setSpeedometerValue] = useState({
    diag_risk_score: 0.5,
    sdoh_risk_score: 0.5,
    comp_risk_score: 0.5
  });
  const [showDataSource1, setDataSource1] = useState(false);
  const [showDataSource2, setDataSource2] = useState(false);
  const [disableDataSource1, setDisableDataSource1] = useState(false);
  const [disableDataSource2, setDisableDataSource2] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [visibleChart1, setVisibleChart1] = useState("");
  const [visibleChart2, setVisibleChart2] = useState("");
  const [showCurrencyChart1, setShowCurrencyChart1] = useState(false);
  const [showCurrencyChart2, setShowCurrencyChart2] = useState(false);
  const [showBarChartContributorType1, setShowBarChartContributorType1] = useState({
    visible: false,
    value: 'slider',
    title: 'Member count sliders and table chart'
  });
  const [showBarChartContributorType2, setShowBarChartContributorType2] = useState({
    visible: false,
    value: 'slider',
    title: 'Member count sliders and table chart',
  });

  const { user } = JSON.parse(localStorage.getItem("user"));
  const { org_guid } = user;
  const carolinaOrgGuid = "10a7319f-b662-4c79-85fd-7fc5c9cc1461"
  

  useEffect(() => {
    if (visibleChart1 === 'coeff') {
      setShowCurrencyChart1(false)
    } else {
      setShowCurrencyChart1(true);
    }
    if (visibleChart2 === 'coeff') {
      setShowCurrencyChart2(false);
    } else {
      setShowCurrencyChart2(true);
    }
  }, [visibleChart1, visibleChart2]);

  const contributorTypes = [
    {
      value: "diag",
      mnemo: "diag",
      title: org_guid === carolinaOrgGuid ? "Diagnosis Contributors" : "Diagnostic Contributors",
      is_diag: 1,
      is_sdoh: 0,
      dial_title: org_guid === carolinaOrgGuid ? "Diagnosis Risk Today" : "Diagnostic Risk Today",
      dial_type: "diag_risk_score"
    },
    {
      value: "sdoh",
      mnemo: "sdohr",
      title: "SDoH Contributors",
      is_diag: 0,
      is_sdoh: 1,
      dial_title: "SDoH Risk Today",
      dial_type: "sdoh_risk_score"
    },
    {
      value: "all",
      mnemo:"all",
      title: org_guid === carolinaOrgGuid ? "Diagnosis & SDoH Contributors" : "Diagnostic & SDoH Contributors",
      is_diag: 1,
      is_sdoh: 1,
      dial_title: "Comprehensive Risk Today",
      dial_type: "comp_risk_score"
    }
  ];
  const [contributorType1, setContributorType1] = useState(contributorTypes[0]);
  const [contributorType2, setContributorType2] = useState(contributorTypes[1]);
  const [pieChartDataType1, setPieChartDataType1] = useState(
    createPieChartSeries(diagnosisData, 'contrib', "label")
  );
  const [pieChartDataType2, setPieChartDataType2] = useState(
    createPieChartSeries(sdohData, 'contrib', "label")
  );

  const  [chartYAxiesOptions, setChartYAxiesOptions] = useState([
    {
      value: 'slider',
      title: 'Member count sliders and table chart',
    },
    {
      value: 'coeff',
      title: 'Member count bar chart and Risk Coefficient',
      label: 'Risk Coefficient'
    },
    {
      value: 'contrib',
      title: `Pie chart of ${toLowerCase(patientSetInfo.cohort_name)}-wide risk contribution`,
      label: 'Risk Contribution'
    }
  ]);

  const [sortByOptions, setSortByOptions] = useState([
    {
      id: 2,
      title: 'Current member count',
      value: 'current_count',
      order: "DESC"
    },
    {
      id: 1,
      title: `${toCapitalize(patientSetInfo.cohort_name)}-wide risk contribution`,
      value: 'pmpm * current_count',
      order: "DESC"
    }
  ]);

  const [sortByDiag, setSortByDiag] = useState(sortByOptions[0]);
  const [sortBySdoh, setSortBySdoh] = useState(sortByOptions[0]);

  const [brokenAxisDiag, setBrokenAxisDiag] = useState(false);
  const [brokenAxisSdoh, setBrokenAxisSdoh] = useState(false);


  const itemOptions = [
    {
      id: 0,
      title: 'Top 7',
      count: 7
    },
    {
      id: 1,
      title: 'Top 5',
      count: 5
    },
    {
      id: 2,
      title: 'All',
      count: 'all'
    },
  ];
  const [itemsDiag, setItemsDiag] = useState(itemOptions[0]);
  const [itemsSdoh, setItemSdoh] = useState(itemOptions[0]);

  const [countDiag, setCountDiag] = useState(0);
  const [maxCountDiag, setMaxCountDiag] = useState(0);
  const [countSdoh, setCountSdoh] = useState(0);
  const [maxCountSdoh, setMaxCountSdoh] = useState(0);
  const [messageDiag, setMessageDiag] = useState('');
  const [messageSdoh, setMessageSdoh] = useState('');

  const fetchData = async (type) => {
    if (patientSetInfo) {
      const { patient_set_id: pid, selected_date } = patientSetInfo;

      setIsFetching(true);
      if (type === "diag") setDiagnosisData([])
      else if (type === "sdoh") setSdohData([]);

      const responseData = await services.contributions({
        filterBy: {
          topContributors: { "diag": itemsDiag.count, "sdoh": itemsSdoh.count },
          sortBy: { "diag": sortByDiag, "sdoh": sortBySdoh },
          count: { "diag": countDiag, "sdoh": countSdoh },
          contributorType1,
          contributorType2
        },
        dataSourceDiag: showDataSource1,
        dataSourceSdoh: showDataSource2,
        selected_date
      }, pid);
      const data = responseData.data;
      setIsFetching(false);

      setDiagnosisData(data.diag_contrib);
      setSdohData(data.sdoh_contrib);

      if (data.diag_contrib.length) {
        setMaxCountDiag(data.max_count_diag);
      }

      if (data.sdoh_contrib.length) {
        setMaxCountSdoh(data.max_count_sdoh);
      }

      if (!data.diag_contrib.length) {
        setDisableDataSource1(true);
        if (type === "initial") setMessageDiag(`Contributor data for this ${patientSetInfo.cohort_name} is not available yet. Please check back later.`);
        else setMessageDiag('No contributors are matching the current selection criteria.')
      }
      else {
        setDisableDataSource1(false);
      }
      if (!data.sdoh_contrib.length) {
        setDisableDataSource2(true);
        if (type === "initial") setMessageSdoh(`Contributor data for this ${patientSetInfo.cohort_name} is not available yet. Please check back later.`);
        else setMessageSdoh('No contributors are matching the current selection criteria.')
      }
      else {
        setDisableDataSource2(false);
      }

      setSpeedometerValue({
        ...speedometerValues,
        diag_risk_score: data.diag_risk_score.toFixed(2),
        sdoh_risk_score: data.sdoh_risk_score.toFixed(2),
        comp_risk_score: data.comp_risk_score.toFixed(2)
      });
    }
  };

  useEffect(() => {
    setPageTitle("FHS: Contributors");
    fetchData('initial', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (analysisType === 'quality_analysis') {
      const newOptions = chartYAxiesOptions.filter(option => option.value !== 'pmpm');
      setChartYAxiesOptions(newOptions);
      const newSortOptions = sortByOptions.filter(option => option.value !== "coeff")
      setSortByOptions(newSortOptions)
    }
    else {
      const newOptions = [...chartYAxiesOptions, {
        value: 'pmpm',
        title: 'Member count bar chart and PMPM Cost Impact',
        label: 'PMPM Cost Impact'
      }];
      setChartYAxiesOptions(newOptions);

      const newSortOptions = [...sortByOptions, {
        id: 0,
        title: 'Cost per member',
        value: 'coeff',
        order: "DESC"
      }]
      setSortByOptions(newSortOptions)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisType])

  const chartSeries1 = useMemo(() => createMixedChartSeries(
    diagnosisData,
    "Member Count",
    showBarChartContributorType1.title,
    'current_count',
    showBarChartContributorType1.value,
    'label',
    {
      text: ""
    },
    {
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true,
        minHeight: 100,
        maxHeight: 140,
        showDuplicates: true,
        trim: true
      },
      tooltip: {
        enabled: false
      }
    },
    [{
      title: {
        text: 'Member Count',
      },
      labels: {
        formatter: (value) => parseInt(value),
      },
    }, {
      opposite: true,
      title: {
        text: showBarChartContributorType1.label
      },
      labels: {
        formatter: (value) => value > 0 ? (showCurrencyChart1 ? "$" : "") + value.toFixed(2) : 0.00,
      },
    },],
    showCurrencyChart1,
    {
      x: {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          const xLabel = diagnosisData[dataPointIndex].label;
          return xLabel;
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showBarChartContributorType1, contributorType1, diagnosisData, showCurrencyChart1]);

  useEffect(() => {
    if( showBarChartContributorType1.value ===  "contrib") {
      setPieChartDataType1(
        createPieChartSeries(diagnosisData, 'contrib',  "label")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBarChartContributorType1, contributorType1, diagnosisData]);

  const chartSeries2 = useMemo(() => createMixedChartSeries(
    sdohData,
    "Member Count",
    showBarChartContributorType2.title,
    'current_count',
    showBarChartContributorType2.value,
    'label',
    {
      text: ""
    },
    {
      labels: {
        trim: true
      }
    },    
    [{
      title: {
        text: 'Member Count',
      },
      labels: {
        formatter: (value) => parseInt(value),
      },
    }, {
      opposite: true,
      title: {
        text: showBarChartContributorType2.label
      },
      labels: {
        formatter: (value) => {
          return value > 0 ? (showCurrencyChart2 ? "$" : "") + value.toFixed(2) : 0.00
        },
      }
      },],
    showCurrencyChart2
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showBarChartContributorType2, contributorType2, sdohData, showCurrencyChart2]);

  useEffect(() => {
      if (showBarChartContributorType2.value === "contrib") {
      setPieChartDataType2(
        createPieChartSeries(sdohData, 'contrib',  "label")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBarChartContributorType2, contributorType2,sdohData])

  useDidMountEffect(() => {
    fetchData("diag");
  }, [showDataSource1, contributorType1, sortByDiag, itemsDiag, countDiag]);

  useDidMountEffect(() => {
    fetchData("sdoh");
  }, [showDataSource2, contributorType2, sortBySdoh, itemsSdoh, countSdoh]);

  useEffect(() => {
    if (showDataSource1 && diagnosisData.length) {
      const findEmpty = diagnosisData.filter((ele) => !ele.data_sources.length);
      if (findEmpty.length === diagnosisData.length) {
        setDisableDataSource1(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosisData]);

  useEffect(() => {
    if (showDataSource2 && sdohData.length) {
      const findEmpty = sdohData.filter((ele) => !ele.data_sources.length);
      if (findEmpty.length === sdohData.length) {
        setDisableDataSource2(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdohData]);

  const [dropdown1, setDropdown1] = useState("");
  const [dropdown2, setDropdown2] = useState("");

  const showMenu1 = (event) => {
    setDropdown1(event.currentTarget);
  };
  const showMenu2 = (event) => {
    setDropdown2(event.currentTarget);
  };

  const visibleBarChart1 = (event) => {
    setVisibleChart1(event.currentTarget.dataset.value);
  };

  const visibleBarChart2 = (event) => {
    setVisibleChart2(event.currentTarget.dataset.value);
  };

  const closeMenu1 = () => {
    setDropdown1(null);
  };
  const closeMenu2 = () => {
    setDropdown2(null);
  };

  const showChart1 = (value) => {
    setDropdown1(null);
    setVisibleChart1(value);
  }
  const showChart2 = (value) => {
    setDropdown2(null);
    setVisibleChart2(value);
  }

  if (!patientSetInfo) {   
    history("/")
    return <></>
  } 

  return (
    <Sidebar pageTitle="Comprehensive Foresight Risk Contributor Analysis">
      <div className="emulationOuter app-bg-color" style={{ paddingTop: "30px" }}>
        <div className="riskFilterOuter">
          <div
            className="diagNosOuter diagNosOuter-resp"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="left-side widget-bg-shadow" id="contributor-dashboard1" style={{ width: analysisType !== "cost_analysis" ? '100%' : info.open ? '75%' : '70%' }}>
             <div className="left-side-top">
                <span className="dignoHD">
                  <Select
                    id="contributor-dashboard1-dropdown1"
                    variant="standard"
                    value={contributorType1.value}
                    onChange={(e) => {
                      setContributorType1(
                        contributorTypes.find(item => item.value === e.target.value))
                    }}
                    MenuProps={{disableScrollLock: true}}
                  >
                    {
                      contributorTypes.map((item, index) => {
                        return <MenuItem value={item.value} key={index}>{item.title}</MenuItem> 
                      })
                    }

                  
                  </Select>
                    <Select
                      className="dashboard1-dropdown2"
                      variant="standard"
                      value={showBarChartContributorType1.value}
                      onChange={(e) => {
                        const selectedOption = chartYAxiesOptions.find(item => item.value === e.target.value)
                        setShowBarChartContributorType1(
                          selectedOption
                        )
                      }}
                      MenuProps={{disableScrollLock: true}}
                      >
                        {
                          chartYAxiesOptions.map((item, index) => {
                            return <MenuItem value={`${item.value}`} onClick={visibleBarChart1} key={index}>{item.title}</MenuItem>
                          })
                        } 
                  </Select>
                  
                  <div className="cursor-pointer">
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={showMenu1}>
                      <img src={settingIcon} alt="setting icon" />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={dropdown1}
                      open={Boolean(dropdown1)}
                      onClose={closeMenu1}
                    >
                      {
                        chartYAxiesOptions.map((item, index) => {
                          return <MenuItem
                            value={`${item.value}`}
                            onClick={() => {
                              const selectedOption = chartYAxiesOptions.find(option => option.value === item.value)
                              setShowBarChartContributorType1(
                                {
                                  ...selectedOption
                                }
                              )
                              showChart1(item.value)
                            }}
                            key={index}
                          >
                            {item.title}
                          </MenuItem>
                        })
                      } 
                    </Menu>
                  </div>

                </span>
                
              </div>

              {     
                
                (() => {
                    if (visibleChart1 && visibleChart1 === "contrib" &&  diagnosisData?.length > 0)                     
                      return  <PieChart data={pieChartDataType1} width={700} />
                    else if ((visibleChart1 && (visibleChart1 === "coeff" || visibleChart1 === "pmpm")) && diagnosisData?.length > 0) 
                      return <MixedChart data={chartSeries1} />
                    else 
                      return (
                        <>
                        <div className="flexGroup mb-2">
                          <span className="filtrDropdown ml-0">
                            <label className="filterLabel" htmlFor="exampleSelect">Sort By</label>
                            <DropdownRiskFilter
                              setData={setSortByDiag}
                              options={sortByOptions}
                              type={"diag"}
                              className="select"  
                            />
                          </span>
                          <span className="filtrDropdown ml-0">
                            <label className="filterLabel" htmlFor="exampleSelect">Items</label>
                            <DropdownRiskFilter
                              setData={setItemsDiag}
                              options={itemOptions}
                              type={"count"}
                            />
                          </span>
                          <span className="filtrDropdown ml-0">
                            <div className="slider-container">
                              <label className="filterLabel" htmlFor="exampleSelect">Counts</label>
                              <CountSlider
                                maxCount={maxCountDiag}
                                count={countDiag}
                                setCount={setCountDiag}
                              />
                            </div>
                          </span>
                          <span className="data-source-filter">
                            <input
                              type="checkbox"
                              className="dataCheckbox"
                              checked={showDataSource1}
                              onChange={() => {
                                setDataSource1(!showDataSource1);
    
                                //GA - Track the show data source checkbox
                                event('show_data_source', { checked: !showDataSource1, type: "Diagnostic Contributors" });
                              }}
                              disabled={disableDataSource1}
                            />
                            <label htmlFor="dataSource">Show Data Source Details</label>
                          </span>
                          <span className="data-source-filter">
                        <input
                          type="checkbox"
                          className="dataCheckbox"
                          checked={brokenAxisDiag}
                          onChange={() => {
                            setBrokenAxisDiag(!brokenAxisDiag);   
                          }}
                        />
                        <label htmlFor="dataSource">Broken Axis</label>
                      </span>
                        </div>
                        {
                          isFetching && !diagnosisData.length
                            ? <AnalysisSkeleton />
                            : !isFetching && !diagnosisData.length
                              ? (
                                <div className="mt-3">
                                  <Alert icon={false} severity="warning" style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    {messageDiag}
                                  </Alert>
                                </div>
                              )
                              : <div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                  <div className="diabet_left_inactive" >
                                    <div className="clearfix">
                                      <div className={`valueLeftBx contributePage`}>
                                        <label className="valueLeft">0%</label>
                                        <label className="valueMid">Member Count</label>
                                        <label className="valueRight">100%</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="diabet_right">
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                      <thead>
                                        <tr>
                                          <th width="33.33%">Risk Coefficient</th>
                                          <th width="33.33%">% Risk Contribution</th>
                                          {analysisType === "cost_analysis" && <th width="33.33%">PMPM Cost Impact</th>}
                                        </tr>
                                      </thead>
                                    </table>
                                  </div>
                                </div>
                                <div className="contributor-scroll-container">
                                  <AnalysisSliders analysisType={analysisType} contributorData={diagnosisData} showDataSource={showDataSource1} showBrokenAxis={brokenAxisDiag} maxCount={maxCountDiag} mnemo={contributorType1["mnemo"]} />
                                </div>
                              </div>
                        }
                        </>
                    )
                })()
              } 
              
              {
                isFetching && !diagnosisData.length ? "" :
                <div className="export-container">                         
                  <CSVLink
                    data={diagnosisData.length > 0 ? getDataToExport(diagnosisData, "Diagnostic Contributors") : []}
                    filename={`Diagnosis Sort By=${sortByDiag.title} Items=${itemsDiag.title} Counts=${countDiag}.csv`}
                    className="export-Btn"
                    >
                  <span className="table-btn-span">
                      <span>Export</span>
                      <GetAppIcon className="table-icon-1" />
                  </span>
                  </CSVLink>       
               </div>
              }
              
            </div>  
           {analysisType === "cost_analysis" && <div className="right-side" id="contributor-dashboard1" style={{ display: "flex", width: info.open ? '23%' : '28%' }}>
              <Speedometer
                value={speedometerValues[contributorType1.dial_type]}
                heading={contributorType1.dial_title}
                label={"Today's Risk Score"}
              />
            </div>}
          </div>

          <div
            className="diagNosOuter diagNosOuter-resp"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="left-side widget-bg-shadow" id="contributor-dashboard2" style={{ width: analysisType !== "cost_analysis" ? '100%' : info.open ? '75%' : '70%' }}>
              <div className="left-side-top">
                <span className="dignoHD">
                  <Select
                    className="dropdown"
                  id="contributor-dashboard2-dropdown1"
                    variant="standard"
                    value={contributorType2.value}
                    onChange={(e) => {
                      setContributorType2(
                        contributorTypes.find(item => item.value === e.target.value));  
                    }}
                    MenuProps={{disableScrollLock: true}}
                  >
                    {
                      contributorTypes.map((item, index) => {
                        return <MenuItem value={item.value} key={index}>{item.title}</MenuItem>
                      })
                    }
                  </Select>

                    <Select
                    className="dropdown dashboard1-dropdown2"
                      variant="standard"
                      value={showBarChartContributorType2.value}
                      onChange={(e) => {
                        const selectedOption = chartYAxiesOptions.find(item => item.value === e.target.value)
                        setShowBarChartContributorType2(
                            selectedOption
                        )
                      }}
                      MenuProps={{disableScrollLock: true}}
                    >
                      {
                        chartYAxiesOptions.map((item, index) => {
                          return <MenuItem value={`${item.value}`} onClick={visibleBarChart2} key={index}>{item.title}</MenuItem>
                        })
                      }
                  </Select>
                  

                  <div className="cursor-pointer">
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={showMenu2}>
                      <img src={settingIcon} alt="setting icon" />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={dropdown2}
                      open={Boolean(dropdown2)}
                      onClose={closeMenu2}
                    >
                      {
                        chartYAxiesOptions.map((item, index) => {
                          return <MenuItem
                            value={`${item.value}`}
                            onClick={() => {
                              const selectedOption = chartYAxiesOptions.find(option => option.value === item.value)
                              setShowBarChartContributorType2(
                                {
                                  // ...showBarChartContributorType2,
                                  ...selectedOption
                                }
                              )
                              showChart2(item.value)
                            }}
                            key={index}
                          >
                            {item.title}
                          </MenuItem>
                        })
                      } 
                    </Menu>
                  </div>
                  
                </span>
                
              </div>

              {
                (() => {
                 
                      if (visibleChart2 && visibleChart2 === "contrib" && sdohData?.length > 0)                     
                        return  <PieChart data={pieChartDataType2} />
                      else if ((visibleChart2 && (visibleChart2 === "coeff" || visibleChart2 === "pmpm")) &&  sdohData?.length > 0) 
                        return <MixedChart data={chartSeries2} />
                      else 
                        return (
                          <>
                            <div className="flexGroup mb-2" >
                              <span className="filtrDropdown ml-0">
                                <label className="filterLabel"  htmlFor="exampleSelect">Sort By</label>
                                <DropdownRiskFilter
                                  setData={setSortBySdoh}
                                  options={sortByOptions}
                                  type={"sdoh"}
                                  className="select"
                                />
                              </span>
                              <span className="filtrDropdown ml-0">
                                <label className="filterLabel" htmlFor="exampleSelect">Items</label>
                                <DropdownRiskFilter
                                  setData={setItemSdoh}
                                  options={itemOptions}
                                  type={"count"}
                                />
                              </span>
                              <span className="filtrDropdown ml-0">
                                <label className="filterLabel" htmlFor="exampleSelect">Counts</label>
                                <div>
                                  <CountSlider
                                    maxCount={maxCountSdoh}
                                    count={countSdoh}
                                    setCount={setCountSdoh}
                                  />
                                </div>
                              </span>
                              <span className="data-source-filter">
                                <input
                                  type="checkbox"
                                  className="dataCheckbox"
                                  checked={showDataSource2}
                                  onChange={() => {
                                    setDataSource2(!showDataSource2);

                                    //GA - Track the show data source checkbox
                                    event('show_data_source', { checked: !showDataSource2, type: "SDoH Contributors" });
                                  }}
                                  disabled={disableDataSource2}
                                />
                                <label htmlFor="dataSource">Show Data Source Details</label>
                              </span>
                              <span className="data-source-filter">
                        <input
                          type="checkbox"
                          className="dataCheckbox"
                          checked={brokenAxisSdoh}
                          onChange={() => {
                            setBrokenAxisSdoh(!brokenAxisSdoh);   
                          }}
                        />
                        <label htmlFor="dataSource">Broken Axis</label>
                      </span>
                            </div>
                            {
                              isFetching && !sdohData.length
                                ? <AnalysisSkeleton />
                                : !isFetching && !sdohData.length
                                  ? (
                                    <div className="mt-3">
                                      <Alert icon={false} severity="warning" style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                        {messageSdoh}
                                      </Alert>
                                    </div>
                                  )
                                  : <div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                      <div className="diabet_left_inactive"  style={{ width: '55%' }}>
                                        <div className="clearfix">
                                          <div className={`valueLeftBx contributePage`}>
                                            <label className="valueLeft">0%</label>
                                            <label className="valueMid">Member Count</label>
                                            <label className="valueRight">100%</label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="diabet_right">
                                        <table width="100%" cellPadding="0" cellSpacing="0">
                                          <thead>
                                            <tr>
                                              <th width="33.33%">Risk Coefficient</th>
                                              <th width="33.33%">% Risk Contribution</th>
                                              {analysisType === "cost_analysis" && <th width="33.33%">PMPM Cost Impact</th>}
                                            </tr>
                                          </thead>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="contributor-scroll-container">
                                      <AnalysisSliders analysisType={analysisType} contributorData={sdohData} showDataSource={showDataSource2} showBrokenAxis={brokenAxisSdoh} maxCount={maxCountSdoh} mnemo={contributorType2["mnemo"]} />
                                    </div>
                                  </div>
                            }
                          </>
                      )
                 
                  })()
              }

              {
                isFetching && !sdohData.length ? "" :
                <div className="export-container">
                <CSVLink
                    data={sdohData.length > 0 ? getDataToExport(sdohData, "SDoh Contributors") : []}
                    filename={`SDoH Sort By=${sortBySdoh.title} Items=${itemsSdoh.title} Counts=${countSdoh}.csv`}
                    className="export-Btn"
                  >
                    <span className="table-btn-span">
                        <span>Export</span>
                      <GetAppIcon className="table-icon-1" />
                    </span>
                </CSVLink>       
              </div>
              }
            </div>
            {analysisType === "cost_analysis" && <div className="right-side" id="contributor-dashboard2" style={{ display: "flex", width: info.open ? '23%' : '28%' }}>
              <Speedometer
                value={speedometerValues[contributorType2.dial_type]}
                heading={contributorType2.dial_title}
                label={"Today's Risk Score"}
              />
            </div>}
          </div>

          <div className="diagNosOuter diagNosOuter-resp">
            <div className="center-side widget-bg-shadow">
              <div className="add-icon-img">
                <img src={addIcon} alt="add-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      </Sidebar>
  );
};

export default ContributorAnalysis;
