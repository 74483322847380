import React, { memo } from "react";
import CustomizedSlider from "../../Components/Sliders/CustomizedSlider/CustomizedSlider";
import { Tooltip } from "@mui/material";
import SegmentedSlider from "../../Components/Sliders/SegmentedSlider/SegmentedSlider";
import "./intervention.scss";
import BrokenAxisRangeSlider from "../../Components/Sliders/BrokenAxisRangeSlider/BrokenAxisRangeSlider";

const InterventionFilters = ({ interventionData, isPreCostAvailable, countType, showDataSource, showBrokenAxis, analysisType   }) => {
  let maxPatientCount = Math.max(...interventionData.map(obj => obj.patient_count));

  let renderInterventionsData = interventionData.map((ele, i) => {

    let dataSources = ele?.data_sources?.map((source) =>{
      const {src_patient_count , ...restData} = source;
      return {...restData, dsrc: src_patient_count}
    })
    
    const formatDeltaCost = (cost) => {
      if (cost === 0) {
        cost = +cost;
      } else {
        cost = -cost;
      }
      return cost.toLocaleString('en-US', { 
        style: 'currency', 
        currency: 'USD', 
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
    };

    const checkIfNegative = (cost) => {
      cost = -cost;
      if(cost < 0) return true;
      else return false;
    };

    const calculateSavings = () => {
      let savings = (ele.cost_pre - ele.cost_post) * 12;
      return savings===0?0:savings;
    };

    const formatSavings = () => {
      const formattedCount = calculateSavings().toLocaleString('en-US', { 
        style: 'currency', 
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return formattedCount;
    };

    /**
     * Fillter class index for the slider
     * @param {*} data 
     * @returns 
     */
    const filterClassIndexes = (data) => {
      var slideIndexArr = [];
      data.forEach(item => {
        item?.data_sources?.forEach(section => {
          slideIndexArr = [ ...slideIndexArr, section.note ];
        });
      });
      return [ ...new Set(slideIndexArr) ];
    };

    const processDataSources = (data_sources) => {
      return data_sources.map(item => {
        const dsrcValue = countType === 'patient_count' ? item.src_patient_count : item.src_service_count;
        
        return {
          contr_instance: item.contr_instance,
          note: item.note,
          dsrc: dsrcValue
        };
      });
    };

    return (
      <div
        key={i}
        className="diabetOut"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="diabet_left_inactive ca-flex filter">
          <div className="diabMetr clearfix" key={i}>
            <Tooltip
              placement="top"
              title={ele.tooltip}
              interactive
              arrow
            >
              <span className="diabLabl">{ele.label}</span>
            </Tooltip>      
            {
                (showDataSource || interventionData) && showBrokenAxis ?
                <BrokenAxisRangeSlider
                  key={ele.id}
                  minElig={0}
                  maxElig={ele[`max_${countType}`]}
                  sections={dataSources || []}
                  sliderValue={ele.patient_count}
                  issue={ele.label}
                  tooltip={ele.tooltip}
                  contr_type={ele.intrv_type}
                  abbr={ele.abbr}
                  maxCount={maxPatientCount}
                  mnemo={"inprov"}
                  slideClassIndexes={filterClassIndexes(interventionData)}
                  type={"intervention"}
                />
                : 
                (showDataSource || interventionData) && !showBrokenAxis ?
                <SegmentedSlider
                  key={i}
                  minElig={0}
                  maxElig={ele[`max_${countType}`]}
                  sections={processDataSources(ele.data_sources || [] , ele.countType)}
                  sliderValue={ele[countType]}
                  issue={ele.label}
                  tooltip={ele.tooltip}
                  contr_type={ele.intrv_type}
                  abbr={ele.abbr}
                  mnemo={"inprov"}
                  slideClassIndexes={filterClassIndexes(interventionData)}
                  type={"intervention"}
                /> 
                :
                <CustomizedSlider
                  sliderValue={ele[countType]}
                  minElig={0}
                  maxElig={ele[`max_${countType}`]}
                  tooltip={ele.tooltip}
                  label={ele.label}
                  abbr={ele.abbr}
                  mnemo={"inprov"}
                  topWidget={true}  
                  intrv_type={ele.intrv_type}
                  rtype={"intervention"}
                />
            }     

          </div>
        </div>
        <div className="diabet_right filter">
          {
            analysisType === 'quality_analysis' ?
              <table width="100%" cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr key={i} style={{ height: "31px" }}>
                    <td width="50%" > {ele.gap_closure_impact || '-'} </td>
                    <td width="50%"> {ele.baseline_gap_closure || '-'} </td>
                  </tr>
                </tbody>
              </table> :
              isPreCostAvailable ?
                <table width="100%" cellPadding="0" cellSpacing="0">
                  <tbody>
                    {
                      (ele.cost_pre || ele.cost_delta) ?
                        <tr key={i} style={{ height: "31px" }}>
                        {ele.cost_delta !== null && ele.cost_delta !== undefined && (
                          <td
                            width="20%"
                            style={checkIfNegative(ele.cost_delta) ? { color: "#ff0000" } : null}
                          >
                            {formatDeltaCost(ele.cost_delta)}
                          </td>
                        )}
                          {ele.cost_pre ? <><td width="30%">{ele.cost_pre ? `$${ele.cost_pre}` : null}</td>
                            <td width="40%">
                              {calculateSavings() === 0 ?
                                <span /> :
                                calculateSavings() > 0 ? (
                                  <span className="incrementSaving" />
                                ) : (
                                  <span className="decrementSaving" />
                                )
                              }
                              {formatSavings()}
                            </td></> :
                            <td width="65%">Costs unavailable</td>}
                        </tr> :
                        <tr key={i} style={{ height: "31px" }}>
                          <td width="100%" style={{ borderRadius: "30px" }}>Costs unavailable</td>
                        </tr>
                    }
                  </tbody>
                </table>
                : i < 1 ? <div className="cost-data-message">Savings PMPM unavailable due to missing cost data</div>
                  : null}
        </div>
      </div>
    );
  });

  return <div className="intervention-scroll-container">{renderInterventionsData}</div>;
};

export default memo(InterventionFilters);

