import React, { useEffect } from 'react';
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
import { notification_type } from '../../constants';
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
    mainBox: {
      maxWidth: "450px",
      margin: "5px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
    },
    listContainer: {
      backgroundColor: "#E7E7E7",
      width: "100%",
      maxWidth: "440px",
      borderRadius: "5px",
      padding: "5px 10px !important",
    },
    selectedListContainer: {
      backgroundColor: "#E3F5FF !important",
    },
    triangle: {
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "20px solid transparent",
      transform: "rotate(90deg)",
    },
    selectedTriangle: {
      borderBottom: "20px solid #E3F5FF !important",
    },
    detailsContainer: {},
    descriptionText: {
      fontFamily: "Lato",
      fontWeight: 500,
      fontSize: "14px !important",
      lineHeight: "21px",
    },
    sourceContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sourceFont: {
      fontFamily: "Lato",
      fontWeight: 400,
      fontSize: "12px !important",
      lineHeight: "20px",
    },
    sourceDate: {
      color: "#797979",
    },
    sourceName: {
      color: "#0066CB",
    },
    recommendedContainer:{
      marginTop: "10px",
      marginRight: "10px"
    },
    recommendedDetailsTitle:{
        padding: '7px 10px'
    },
    recommendedDetailsInnerContainer1:{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: "#E7E7E7",
    },
    recommendedDetailsInnerContainer2:{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px 10px !important'
    },
    toggleButton: {
      border: "0px !important",
      margin: "5px",
      borderRadius: "5px !important",
    },
    upTrue: {
      backgroundColor: "rgb(104, 235, 141) !important",
    },
    upFalse: {
      backgroundColor: "rgba(104, 235, 141, 0.3) !important",
    },
    downTrue: {
      backgroundColor: "rgb(235, 104, 104) !important",
    },
    downFalse: {
      backgroundColor: "rgba(235, 104, 104, 0.3) !important",
    },
    gridContainer:{
      height: "260px",
      overflow: "auto",
      marginTop: "0"
    },
    skeletonContainer:{
      padding: "0 2% !important"
    },
    noteText: {
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
      color: "#141414"
    },
    dateTitle: {
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "20px",
      color: "#0066CB",
    },
    dateSpan: {
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      color: "#141414",
    }
  }));

  const RecommendedList = ({
    id,
    title,
    date,
    index,
    selectedIndex,
    handleClick,
  }) => {
    const classes = useStyles();
    return (
      <Box className={classes.mainBox} onClick={() => handleClick(index, id)}>
        <Box
          className={`${
            index === selectedIndex ? classes.selectedListContainer : ""
          } ${classes.listContainer}`}
        >
          <Box className={classes.detailsContainer}>
            <Typography className={classes.descriptionText}>
              {title}
            </Typography>
          </Box>
          <Box className={classes.sourceContainer}>
            <Typography className={`${classes.sourceFont} ${classes.sourceDate}`}>
              {date}
            </Typography>
          </Box>
        </Box>
        <Box
          className={`${
            index === selectedIndex ? classes.selectedTriangle : ""
          } ${classes.triangle}`}
        ></Box>
      </Box>
    );
  };

const IpErReadmissionTab = ({selectedNotification}) => {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedItem, setSelectedItem] = useState([])
    const { state } = useContext(WcchcPatientSummaryContext);
    const [alerts, setAlerts] = useState({})
    const [allAlerts, setAllAlerts] = useState([]);

    const ipErReadmits = ["IP_ER_VISIT", "READMISSION"];
    const newIpEr = ["IP_VISIT", "ER_VISIT", "READMISSION"];

    const groupByType = (alerts) => {
      return alerts.reduce((acc, notification) => {
        const { newType } = notification;
        if (!acc[newType]) {
          acc[newType] = [];
        }
        acc[newType].push(notification);
        return acc;
      }, {});
    };
    
    const uniqueValue = (data) => {
      const uniqueNotes = {};

      data.forEach(entry => {
        const noteType = entry.is_readmit ? "READMISSION" : entry.is_ip ? "IP_VISIT" : "ER_VISIT";
        if (!uniqueNotes[noteType]) {
          uniqueNotes[noteType] = {
            date: entry.note_date,
            type: noteType
          };
        }
      });

      return uniqueNotes
    }

    useEffect(() => { 
      if (state.allSmartAlerts.length) {
        const filteredAlerts = state.allSmartAlerts.filter((sa) => ipErReadmits.includes(sa.type));        
        const data = uniqueValue(filteredAlerts);
        setAlerts(data);
        const modifiedData = filteredAlerts.map(item => {
          if (item.is_readmit === true) {
            item.newType = "READMISSION";
          } else if (item.is_ip === true) {
            item.newType = "IP_VISIT";
          } else {
            item.newType = "ER_VISIT";
          }
          return item;
      });
        const newData = groupByType(modifiedData);
        setAllAlerts(newData)
        const dataKeys = Object.keys(newData);
        const sortedKeys = dataKeys.sort((a, b) => newIpEr.indexOf(a) - newIpEr.indexOf(b));

        setSelectedItem(newData[selectedNotification.newType || dataKeys[0]] || [])
        selectedNotification && newIpEr.includes(selectedNotification.newType) 
          ? setSelectedIndex(dataKeys.findIndex((i) => i === selectedNotification.newType))
          : setSelectedIndex(newIpEr.indexOf(sortedKeys[0]))
      }  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.allSmartAlerts, selectedNotification]); 

    const handleClick = (index, type) => {
        setSelectedIndex(index);
        setSelectedItem(allAlerts[type])
    };

    return(
      <Grid
        container
        direction="row"
        spacing={2}
        className={classes.gridContainer}
      >
        {
          state.isFetchingAllSmartAlerts ? (
            <Grid item xs={12}>
              {new Array(5).fill(0).map((_, index) => (
                <Box className={classes.skeletonContainer}>
                  <Skeleton key={index} height={50} sx={{width: "100%"}}/>
                </Box>
              ))}
            </Grid>
          )
          :
          Object.keys(alerts).length === 0 ?(
            <>
              <Grid item xs={4}>
                <Box className={classes.mainBox}>
                  <Typography className={classes.descriptionText}>
                    No Hospital Admits/ER Visits/Readmission data available
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}></Grid>
            </>
          )
          :
          <>
          <Grid item xs={4}>
            {newIpEr.map((type, index) => { 
              if(!alerts[type]) return null;
              
              return (
                <RecommendedList
                  key={index}
                  index={index}
                  selectedIndex={selectedIndex}
                  handleClick={() => handleClick(index, alerts[type].type)}
                  title={notification_type[alerts[type].type]}
                  date={state.lastUpdatedDate}
                />
              );
            })}            
          </Grid>
          <Grid item xs={8} my={3} >            
            {selectedItem?.map((item,index) => {
              const dateTime = dayjs(item?.identified_date, 'YYYY-MM-DD');
              const formattedDate = dateTime.format('MM/DD/YYYY');
              const dischargeDate = dayjs(item?.discharge_date).format('MM/DD/YYYY');
              const readmissionDate = dayjs(item?.date_of_readmission).format('MM/DD/YYYY');
              return (
                <div key={index} style={{ paddingBottom: "15px" }}>
                  <Typography className={classes.noteText}>
                    {item?.is_readmit
                      ? `The patient was readmitted to the hospital following an inpatient admission on ${readmissionDate}`
                      : item?.is_ip
                      ? "Patient was hospitalized."
                      : "Patient had an Emergency room visit on:"}
                  </Typography>
                  <Typography className={classes.dateTitle}>
                    {item?.is_readmit ? 'Previously Admitted Date:' : item?.is_ip
                      ? `Admit Date:`
                      : `Visit Date:`}{" "}
                    : <span className={classes.dateSpan}>{formattedDate}</span>
                  </Typography>
                  {item?.is_ip && (
                    <Typography className={classes.dateTitle}>
                      Discharge Date:{" "}
                      <span className={classes.dateSpan}>{dischargeDate}</span>
                    </Typography>
                  )}
                </div>
              );
            })}
          </Grid>
          </>
        }
      </Grid>
    )
};

export default IpErReadmissionTab